import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Amplify from "@aws-amplify/core";
//import TagManager from "react-gtm-module";
//import Analytics from "@aws-amplify/analytics";
import * as serviceWorker from "./serviceWorker";
import { datadogRum } from "@datadog/browser-rum";

/*const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};*/

datadogRum.init({
  applicationId: "aa8de55f-97d8-4f3f-981b-be1cca4a1cfd",
  clientToken: "pub201910d89e3f01fb47ad42a68389cdbd",
  site: "datadoghq.com",
  service: "airr",
  env: process.env.REACT_APP_DD_ENV,
  //  version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_REGION,
    region: process.env.REACT_APP_REGION
  },
  Analytics: { disabled: true }
});

/*const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: process.env.REACT_APP_PINPOINT_APP_ID,
    // Amazon service region
    region: process.env.REACT_APP_REGION,
    mandatorySignIn: false
  }
};*/

//Analytics.configure(analyticsConfig);
//TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
